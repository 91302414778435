import { useEffect } from 'react';
import { CLICKED_ITEM_OBJ } from '@/utils/constants';
import { scrollOnPageLoad } from '@/utils/scrollOnPageLoad';

export function persistScrollPosition() {
  const scrollConfig = scrollOnPageLoad();
  if (!scrollConfig.toScroll) {
    return;
  }
  const { x_scroll, y_scroll } = scrollConfig;
  setTimeout(() => {
    window.scrollTo(x_scroll, y_scroll);
    sessionStorage.removeItem(CLICKED_ITEM_OBJ);
  }, 300);
}

export function useScrollAnchor() {
  useEffect(() => {
    const wasPageRefreshed = isPageRefreshed();
    if (!wasPageRefreshed) persistScrollPosition();
    else resetScrollPosition();
  }, []);

  // Check was it refreshed/reloaded using the browser's reload button
  function isPageRefreshed() {
    const performance = window.performance;
    if (!performance) {
      return false;
    }
    return (
      (performance.navigation && performance.navigation.type === 1) ||
      performance
        .getEntriesByType('navigation')
        .map((nav: any) => nav.type)
        .includes('reload') ||
      performance?.navigation?.type == performance?.navigation?.TYPE_RELOAD
    );
  }

  function resetScrollPosition() {
    setTimeout(() => {
      sessionStorage.removeItem(CLICKED_ITEM_OBJ);
      window.scrollTo(0, 0);
    }, 300);
  }

  return null;
}
