import { PromosContainer } from '../../Common/PromosContainer';

const StoreOffersSection = ({ storePromos, onPromoClick }) => {
  return (
    <PromosContainer
      promos={storePromos}
      onPromoClick={onPromoClick}
      customClasses={'tw-flex tw-flex-col md:tw-px-[24px] md:tw-py-[21px]'}
      primaryTextClasses="tw-text-[13px]/[18px] tw-font-medium md:tw-text-[16px]/[20px] md:tw-font-semibold"
      secondaryTextClasses="tw-text-[12px]/[20px] tw-font-normal md:tw-text-[15px]/[20px]"
    />
  );
};

export default StoreOffersSection;
