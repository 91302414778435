import React from 'react';
import { useSelector } from 'react-redux';
// import interLoader from '../../../public/assets/images/inter-loader.gif';
import { GlobalCfeLoader } from 'src/components/WidgetMaker/WidgetDnD/GlobalCfeLoader';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';

export function FullPageLoader() {
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();

  const { globalLoader } = useSelector((state) => ({
    globalLoader: state.commonReducer.globalLoader,
  }));

  return (
    globalLoader && (
      <div className="global-loader">
        <GlobalCfeLoader
          type={globalStyle?.appLoader?.loaderId || 'ellipsis'}
          color={globalStyle?.appLoader?.color}
        />
      </div>
    )
  );
}
