import { useEffect } from 'react';
import { toastNotifySuccess } from '@/components/Common/Toast';
import { saveATHSEvent } from '@/redux/actions';
import { useDispatch } from 'react-redux';

export function useInstallApp(store) {
  const dispatch = useDispatch();

  useEffect(() => {
    const storeDomain = store?.store_info?.domain;

    const athsBeforeInstall = (e) => {
      e.preventDefault();
      dispatch(saveATHSEvent(e));
    };

    window.addEventListener('beforeinstallprompt', athsBeforeInstall);
    window.addEventListener('appinstalled', () => {
      // (Old code is pasted here) 5 is passed as number as this would need to be changed on all instances
      localStorage?.setItem(`${storeDomain}-aths-noOfCount`, 5 as any);
      toastNotifySuccess('Your App will be visible in sometime !');
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', athsBeforeInstall);
    };
  }, []);

  return null;
}
