import { ADMIN_WIDGETS } from 'src/constants/widgets';
import store from '../redux/store';
import { deviceWidth } from '@/utils/deviceWidth';

const checkIfWhatsAppVisible = () => {
  const storeTheme = store.getState().storeReducer?.store?.theme;
  const isCustomTheme = !!storeTheme?.custom_theme_flag;
  const isBrandWebsiteTheme = !!storeTheme?.brand_website_flag;
  const headerComponent = storeTheme?.components?.Header?.[0];
  const isHeader14 = headerComponent?.name === 'Header14';
  const isNewHeader = headerComponent?.name === ADMIN_WIDGETS.HEADER.name;

  let whatsAppVisibilityFromHeader =
    !!headerComponent?.config?.hidden_components?.whatsapp_view;

  if (isNewHeader) {
    whatsAppVisibilityFromHeader =
      headerComponent?.config?.navIcons_section?.componentData?.iconsList?.find(
        (item) => item.name === 'contact'
      )?.visibility?.desktopHeader;
    if (whatsAppVisibilityFromHeader === undefined) whatsAppVisibilityFromHeader = true;
  }

  return isNewHeader
    ? false // whatsAppVisibilityFromHeader
    : isCustomTheme && isHeader14
      ? whatsAppVisibilityFromHeader
      : !isBrandWebsiteTheme && deviceWidth > 786;
};

export default checkIfWhatsAppVisible;
