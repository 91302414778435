import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';

export const useSetBuilderContextWidgets = () => {
  const widgets = useSelector((state: any) => state?.jsonWidgetsReducer?.widgets || []);

  const {
    widgetContextActions: { setWidgets },
    widgetContextState: { isBuilder },
  } = useWidgetDndContextData();

  useEffect(() => {
    if (widgets && !isBuilder) {
      setWidgets(widgets);
    }
  }, [widgets]);

  return null;
};
