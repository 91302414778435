import { getStoreActiveLeadGenTemplate } from '@/redux/actions';
import checkIfThemeEditorOrPreview from '@/utils/checkIfThemeEditorOrPreview';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export function useActiveLeadGenStatus(storeId, checkLeadgenPopup) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (storeId) {
      if (!checkIfThemeEditorOrPreview()) {
        dispatch(
          getStoreActiveLeadGenTemplate(
            { store_id: storeId },
            (data) => data.status && setTimeout(checkLeadgenPopup, 5000)
          )
        );
      }
    }
  }, [storeId]);

  return null;
}
