import { useEffect } from 'react';
import { getAuthToken } from '@/utils/getAuthToken';
import { useDispatch } from 'react-redux';
import { authenticateUser, setLogout } from '@/redux/actions';

export function useCheckUserLoginStatus() {
  const dispatch = useDispatch();

  useEffect(() => {
    const authToken = getAuthToken();
    if (authToken) {
      const authData = {
        auth_token: authToken,
        service: '',
      };
      dispatch(authenticateUser(authData));
    } else {
      dispatch(setLogout());
    }
  }, []);

  return null;
}
