import { useEffect, useState } from 'react';
import FloatingButtonAndDrawer from '@/components/FloatingButtonAndDrawer';
import OfferIcon from '@/assets/svgExports/OfferIcon';
import TabsContainer from '../TabsContainer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import {
  getAllPromos,
  getPaymentOffersList,
  saveCouponsList,
  togglePromoDetailModal,
} from 'src/redux/actions';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import StoreOffersSection from './PromosSections/StoreOffersSection';
import BankOffersSection from './PromosSections/BankOffersSection';
import { useFeatureLocks } from 'src/hooks/useFeatureLocks';

const PromosDrawerAndButton = () => {
  const dispatch = useDispatch();

  const { storeId, userId, paymentOffersList, promos } = useSelector(
    (state: RootState) => ({
      storeId: state.storeReducer.store.store_id,
      userId: state.userReducer.data?.user_id || 0,
      paymentOffersList: state.promoReducer.paymentOffersList || [],
      promos: state?.promoReducer?.couponsList,
    })
  );

  const { couponsFeatureStatus } = useFeatureLocks();
  const isCouponsFeatureLocked = !!couponsFeatureStatus?.isLocked;

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showPromos, setShowPromos] = useState(false);

  const tabsData = [
    {
      text: 'Store Offers',
    },
    {
      text: 'Bank Offers',
    },
  ];

  useEffect(() => {
    if (storeId && !isCouponsFeatureLocked && !promos.length) {
      getPromos();
    }
  }, [storeId, isCouponsFeatureLocked]);

  function getPromos() {
    const data = {
      storeId,
      userId,
    };
    dispatch(
      getAllPromos(data, (allPromos) => dispatch(saveCouponsList({ coupons: allPromos })))
    );
    dispatch(getPaymentOffersList(storeId));
  }

  function openPromoModal(promoId) {
    dispatch(
      togglePromoDetailModal({
        show: true,
        promoId: promoId,
      })
    );
    IS_DESKTOP && setShowPromos(false);
  }

  const showStoreOffers = activeTabIndex === 0;

  return (
    !isCouponsFeatureLocked &&
    !!promos.length && (
      <>
        <FloatingButtonAndDrawer
          buttonConfig={{
            text: 'Offers',
            icon: <OfferIcon />,
            onClick: () => setShowPromos(true),
          }}
          showDrawer={showPromos}
          onClose={() => setShowPromos(false)}
          drawerHeading="Offers"
          additionalHeaderComponent={
            !!paymentOffersList?.length && (
              <TabsContainer
                activeTabIndex={activeTabIndex}
                setActiveTabIndex={setActiveTabIndex}
                tabsData={tabsData}
                tabClasses="!tw-text-[14px]/[20px] md:!tw-text-[18px]/[20px] !tw-px-[20px] md:!tw-px-[32px] tw-pb-[18px]"
              />
            )
          }
        >
          {showStoreOffers ? (
            <StoreOffersSection storePromos={promos} onPromoClick={openPromoModal} />
          ) : (
            <BankOffersSection paymentOffersList={paymentOffersList} />
          )}
        </FloatingButtonAndDrawer>
      </>
    )
  );
};

export default PromosDrawerAndButton;
