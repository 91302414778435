import { useEffect } from 'react';

export function useSaveQueryParams() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get('utm_source');
    if (localStorage?.getItem('utm_source') !== utmSource) {
      localStorage?.setItem('utm_source', utmSource);
    }
  }, []);

  return null;
}
