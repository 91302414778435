import store from '../redux/store';

export const isFormPresent = () => {
  const storeTheme = store.getState()?.storeReducer?.store?.theme;
  const themeFormConfig = storeTheme?.components?.Body?.filter(
    (comp) => comp.sub_type === 'Form'
  );

  if (themeFormConfig && themeFormConfig?.length > 0) return true;
  return false;
};
