/**
 * @param {object} storeInfo
 * @param {object} seoDetails
 * @return {string}
 */
export const getMetaDescription = (storeInfo, seoDetails = null) => {
  if (!storeInfo) return '';
  if (seoDetails) return seoDetails.description;
  const description = storeInfo?.default_seo?.meta_description;
  return description || '';
  // const businessTypes = storeInfo.store_businesses;
  // const isAddresVisible = storeInfo?.address?.visibility === 1 ? true : false;
  // let match = 13;
  // let description = '';
  // // default
  // if (businessTypes && businessTypes.length > 1) {
  //   match = 11;
  // } else if (
  //   businessTypes &&
  //   businessTypes.length === 1 &&
  //   businessTypes[0].business_id === 8
  // ) {
  //   match = 11;
  // } else if (businessTypes && businessTypes.length === 0) {
  //   match = 11;
  // }

  // // Genral store
  // if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 1)
  //   match = 1;
  // // Pharmacy & Wellness
  // if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 2)
  //   match = 2;
  // // Mobile & Electronics
  // if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 3)
  //   match = 3;
  // // Clothing & Fashion
  // if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 4)
  //   match = 4;
  // // Resturants, Food Joints & Bakery
  // if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 5)
  //   match = 5;
  // // Stationary & Office Supplies
  // if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 6)
  //   match = 6;
  // // Arts, Jewellery & Collections
  // if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 7)
  //   match = 7;
  // // Fruits & Vegetables
  // if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 13)
  //   match = 8;
  // // Furniture & Home Decor
  // if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 14)
  //   match = 9;
  // // Beauty & Cosmetics
  // if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 15)
  //   match = 10;
  // // real estate
  // if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 16)
  //   match = 12;

  // switch (match) {
  //   case 1:
  //     description = `Buy and Order online from ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ''
  //     }| Online Grocery & Dairy Store - ${`${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ''
  //     }`}. Order Now!`;
  //     break;
  //   case 2:
  //     description = `Buy and Order online from  ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ''
  //     }| Online Pharmacy & Wellness Store - ${`${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ''
  //     }`}. Order Now!`;
  //     break;
  //   case 3:
  //     description = `Buy and Order online from ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ''
  //     }| Online Mobile & Electronics Store - ${`${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ''
  //     }`}. Order Now!`;
  //     break;
  //   case 4:
  //     description = `Buy and Order online from ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ''
  //     }| Online Fashion Store - ${`${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ''
  //     }`}. Order Now!`;
  //     break;
  //   case 5:
  //     description = `Order Food online from ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ''
  //     }|  Now order online & pay using UPI, Paytm, GooglePay, PhonePe at ${
  //       storeInfo.store_info?.name
  //     }${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
  //     } . Order Now!`;
  //     break;
  //   case 6:
  //     description = `Buy and Order online from ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ''
  //     }| Online Stationary Store - ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
  //     }. Order Now!`;
  //     break;
  //   case 7:
  //     description = `Buy and Order online from ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ''
  //     }| Online Arts, Jewellery & Collections Store - ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
  //     }. Order Now!`;
  //     break;
  //   case 8:
  //     description = `Buy and Order online from ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ''
  //     }| Online Fruits & Vegetables Store - ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
  //     }. Order Now!`;
  //     break;
  //   case 9:
  //     description = `Buy and Order online from ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ''
  //     }| Online Furniture & Home Decor Store - ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
  //     }. Order Now!`;
  //     break;
  //   case 10:
  //     description = `Buy and Order online from ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ''
  //     }| Online Beauty & Cosmetics Store - ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
  //     }. Order Now!`;
  //     break;
  //   case 11:
  //     description = `Buy and Order online from ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ''
  //     }| Now order online & pay using UPI, Paytm, GooglePay, PhonePe at ${
  //       storeInfo.store_info?.name
  //     }${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
  //     }. Order Now!`;
  //     break;
  //   case 12:
  //     description = `Buy / Sell / Rent your real estate dream property from ${
  //       storeInfo.store_info?.name
  //     }${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ''
  //     } | Real Estate Property ${storeInfo.store_info?.name}. Explore Now!`;
  //     break;

  //   default:
  //     description = `Buy and Order online from ${storeInfo.store_info?.name}${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ''
  //     }| Now order online & pay using UPI, Paytm, GooglePay, PhonePe at ${
  //       storeInfo.store_info?.name
  //     }${
  //       isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
  //     }. Order Now!`;
  //     break;
  // }

  // return description;
};
