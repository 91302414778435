import BankOffers from '../../BankOffers';

const BankOffersSection = ({ paymentOffersList }) => {
  return (
    <BankOffers
      showAllPromos={true}
      paymentOffersList={paymentOffersList}
      isCartPage={false}
    />
  );
};

export default BankOffersSection;
