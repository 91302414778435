import { isThemeBrandWebsite } from '@/utils/isThemeBrandWebsite';
import { isFormPresent } from '@/utils/isFormPresent';
import checkIfWhatsAppVisible from '@/utils/checkIfWhatsAppVisible';
import { useMemo } from 'react';

export function useGetConditionalRenderingData(storeData) {
  const conditionals = useMemo(() => {
    const floatingButtonComponent = storeData?.theme?.components?.Body?.filter(
      (comp) => comp.sub_type === 'FloatingButton'
    );
    const isFloatingComponentValid =
      Array.isArray(floatingButtonComponent) && floatingButtonComponent?.length > 0;
    const floatingComponentData = floatingButtonComponent?.[0] || {};
    const floatingComponentConfig = floatingComponentData?.config;

    const isBrandWebsite = isThemeBrandWebsite();
    const formPresent = isFormPresent();

    const showModernForm = isBrandWebsite && formPresent;

    const showEnquiryForm =
      showModernForm &&
      isFloatingComponentValid &&
      floatingComponentConfig?.enquiryForm?.isEnabled;

    const showFloatingButton =
      isBrandWebsite &&
      isFloatingComponentValid &&
      (floatingComponentConfig?.scrollToTop?.isEnabled ||
        floatingComponentConfig?.whatsappCTA?.isEnabled);

    const showFloatingWhatsappButton = checkIfWhatsAppVisible();

    return {
      floatingComponentData,
      showModernForm,
      showEnquiryForm,
      showFloatingButton,
      showFloatingWhatsappButton,
    };
  }, [storeData]);

  return conditionals;
}
