import { Drawer } from '@mui/material';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import { getThemeColor } from 'src/utils/getThemeColor';
import React, { ReactNode, useState } from 'react';
import CloseIconBlack from '@/assets/svgExports/CloseIconBlack';

interface FloatingButtonAndDrawerProps {
  buttonConfig: {
    text: string;
    icon?: any;
    onClick?: () => void;
  };
  showDrawer?: boolean;
  onClose?: () => void;
  drawerHeading?: string;
  additionalHeaderComponent?: React.JSX.Element;
  children?: ReactNode | undefined;
  showCloseIcon?: boolean;
}

export const FloatingButtonAndDrawer: React.FC<FloatingButtonAndDrawerProps> = ({
  buttonConfig,
  showDrawer,
  onClose,
  drawerHeading,
  additionalHeaderComponent,
  children,
  showCloseIcon,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const themeColor = getThemeColor();

  const onCloseDrawer = () => setOpenDrawer(false);
  const onOpenDrawer = () => setOpenDrawer(true);

  const showFinal = showDrawer ?? openDrawer;
  const onCloseFinal = onClose ?? onCloseDrawer;
  const onButtonClick = buttonConfig.onClick ?? onOpenDrawer;

  function renderFloatingButton() {
    return (
      <div
        className={`tw-fixed  tw-bottom-1/2 tw-right-0 tw-origin-bottom-right -tw-rotate-90 `}
      >
        <button
          className={`tw-flex tw-cursor-pointer tw-items-center tw-gap-[4px] tw-border-none tw-px-[28px] tw-py-[8px] tw-text-[15px]/[20px] tw-font-bold tw-text-white md:tw-py-[12px]  `}
          style={{
            backgroundColor: themeColor,
          }}
          onClick={onButtonClick}
        >
          {buttonConfig.icon || null} {buttonConfig.text}
        </button>
      </div>
    );
  }

  function renderDrawerHeader() {
    return (
      <div className="tw-sticky tw-top-0 tw-z-50 tw-border-b tw-border-solid tw-border-[#E3E3E3] tw-bg-white">
        <div className="tw-flex tw-items-center tw-gap-[12px] tw-px-[20px] tw-py-[32px] tw-text-[18px]/[20px] tw-font-semibold md:tw-px-[24px]">
          {(showCloseIcon ?? true) && (
            <span className="tw-flex tw-cursor-pointer" onClick={onCloseFinal}>
              <CloseIconBlack size={IS_DESKTOP ? 16 : 12} />
            </span>
          )}
          {drawerHeading}
        </div>
        {additionalHeaderComponent}
      </div>
    );
  }

  function renderDrawerContent() {
    return <div className="tw-flex tw-flex-col">{children}</div>;
  }

  return (
    <>
      {renderFloatingButton()}
      <Drawer
        open={showFinal}
        onClose={onCloseFinal}
        classes={{
          paper: IS_DESKTOP ? 'tw-w-[40%] tw-min-w-[596px]' : 'tw-h-full',
        }}
        anchor={IS_DESKTOP ? 'right' : 'bottom'}
        transitionDuration={IS_DESKTOP ? 300 : 400}
      >
        {renderDrawerHeader()}
        {renderDrawerContent()}
      </Drawer>
    </>
  );
};

export default FloatingButtonAndDrawer;
